export default [
  {
    title: "Electrical installation, maintenance and inspection",
    content:
      "Design and installation for new build properties and renovations projects. Additions and alterations undertaken, ranging from additional socket outlets or lighting points in a one bedroom flat to full rewire in a four bedroom period property. All of our systems are designed and installed in compliance with BS7671 regulations.",
  },
  {
    title: "Data and AV infrastructure",
    content:
      "Installation of data cabling systems, telephone and CCTV systems. RJ45, CAT 5& 6, CO-AX. Whilst undertaking any work for you we can discuss the advantages and potential cost savings of future proofing your project for any network, media or audio visual requirements from expanding your home network to a high specification home cinema system.",
  },
  {
    title: "Consumer unit replacement",
    content:
      "Consumer unit (also called the 'fuse box') is used to provide safe isolation, fault and overload protection for all your electrical circuits. We can inspect, design, install , add and upgrade consumer units.",
  },
  {
    title: "Industrial machinery wiring installation",
    content:
      "We carry out design, install and upgrade of single and three phase installation. We work with Single, multicore, SWA, flex cable, PVC , steel conduits, trunking, trays etc. We are also experienced with installing and extensive fault finding control systems, including cabinet wiring and components, AC & DC drive & motor systems, busbar power distribution systems.",
  },
  {
    title: "EICR",
    content:
      "Electrical Installation Condition Reports. EICR report is to confirm, so far as reasonably practicable, whether or not the electrical installation is in a satisfactory condition for continued service. The report should identify any damage, deterioration,defects and/or conditions which may give rise to danger. We can also quote to rectify any of the outstanding remedials.",
  },
  {
    title: "Smart home electrics",
    content:
      "All of your smart home requirements can be installed on a stand alone basis or be incorporated as part of a full or partial rewire depending on your requirements, including but not limited to the following; Network controlled lighting and heating, Home automation/blinds/shutters/etc, Smart alarms.",
  },
  {
    title: "Outdoor Lighting & Power",
    content:
      "We can design and install outdoor lighting and power requirements. Whether this is a small lighting system for entertaining on summer evenings, to a complete electrical installation for a new garage or garden building. All designs can incorporate conventional control or integrate with a smart home. We will also ensure that all work complied with BS7671 as you and your family's safety is our number one priority.",
  }
];
