export default [
  {
    content: 'Dear Mr Miller, just a note to say how delighted we are with the trap control units you designed and built. To be honest they are far superior to the originals and we shall definitely be ordering some more for our other ground. Thank you for a good job.',
    customerName: 'I Huggins',
    customerLocation: 'Byhurst'
  },
  {
    content: 'Having had various electrical work carried out I can highly recommend their services 100% with no problem. Regarding outside garden lighting, installing power to outside workshop. House work for LED spot lighting and additional socket points added to rooms all done very efficiently.',
    customerName: 'A Ferris',
    customerLocation: 'Chessington'
  },
  {
    content: 'Warren and his team were very prompt and courteous, did a very good job and were very responsive. Definitely would recommend!',
    customerName: 'D Popov',
    customerLocation: 'Englefield Green'
  },
  {
    content: 'Very professional company who did their work efficiently, on time and on budget - were very unobtrusive and considerate. Would highly recommend them. 5 star service!',
    customerName: 'N Slyfield',
    customerLocation: 'Guildford'
  },
  {
    content: 'Work was carried out to a very professional and high standard. Warren was prompt, polite and kept us informed at each stage.',
    customerName: 'T Wilson',
    customerLocation: 'Chertsey'
  },
  {
    content: 'Thank you very much for the recent work you undertook on my outdoor lighting and for the efficiency with which it was executed. I am delighted, both with the driveway lanterns and the up lighting in the back garden which beautifully accentuate the trees at night. I shall certainly recommend you to others.',
    customerName: 'J Saville',
    customerLocation: 'Esher'
  },
];
