import React from "react";
import Button from "../components/Button";
import LabelText from "../components/LabelText";

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: "",
    };
  }

  render() {
    const { status } = this.state;
    return (
      <section
        id="contact"
        className="py-16 lg:py-20 text-center bg-primary-darker text-secondary"
      >
        <div className="container mx-auto px-5 sm:px-8">
          <LabelText className="mb-8 text-xl text-center">CONTACT US</LabelText>
          <p className="mt-4 text-xl font-light">
            Contact us for a <strong>FREE</strong> quote - or to <u>book</u>{" "}
            with us.
          </p>
          <div className="mt-4 text-lg font-light text-secondary">
            <form
              onSubmit={this.submitForm}
              action="https://formspree.io/mzbjoglr"
              method="POST"
              className="text-center"
            >
              <div className="mb-6">
                <input
                  className="w-2/3 max-w-sm md:max-w-lg font-primary bg-secondary-lighter appearance-none border-2 bg-secondary-lighter rounded w-full py-2 px-4 text-secondary-darker leading-tight focus:outline-none focus:bg-white focus:border-primary-lighter"
                  id="full-name"
                  name="full-name"
                  type="text"
                  placeholder="Your name"
                />
              </div>
              <div className="mb-6">
                <input
                  className="w-2/3 max-w-sm md:max-w-lg bg-secondary-lighter appearance-none border-2 bg-secondary-lighter rounded w-full py-2 px-4 text-secondary-darker leading-tight focus:outline-none focus:bg-white focus:border-primary-lighter"
                  id="phone-number"
                  name="phone-number"
                  type="text"
                  placeholder="Your phone number"
                />
              </div>
              <div className="mb-6">
                <input
                  className="w-2/3 max-w-sm md:max-w-lg bg-secondary-lighter appearance-none border-2 border-secondary-lighter rounded w-full py-2 px-4 text-secondary-darker leading-tight focus:outline-none focus:bg-white focus:border-primary-lighter"
                  id="email-address"
                  name="email-address"
                  type="text"
                  placeholder="Your email address"
                />
              </div>
              <div className="mb-6">
                <textarea
                  className="w-2/3 max-w-sm md:max-w-lg bg-secondary-lighter appearance-none border-2 border-secondary-lighter rounded w-full py-2 px-4 text-secondary-darker leading-tight focus:outline-none focus:bg-white focus:border-primary-lighter"
                  id="message"
                  name="message"
                  type="text"
                  placeholder="Your message"
                  rows="5"
                />
              </div>
              <div>
                {status === "SUCCESS" ? (
                  <p className="mt-4">Thanks, we shall respond to you promptly.</p>
                ) : (
                  <Button size="md" type="submit">
                    SEND
                  </Button>
                )}
                {status === "ERROR" && <p className="mt-4">Ooops! There was an error.</p>}
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    console.log("*****", data, form)
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}

// const ContactForm = () => (

// );

// export default ContactForm;
