import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
// Data
import servicesData from "../data/services-data";
import testimonialsData from "../data/testimonials-data";
// Components
import Layout from "../components/layout/Layout";
import SplitSection from "../components/SplitSection";
import ContactForm from "../components/ContactForm";
import Button from "../components/Button";
import LabelText from "../components/LabelText";
import AnchorLink from "react-anchor-link-smooth-scroll";
// SVGs
import TickIcon from "../svg/TickIcon";
import ShieldIcon from "../svg/ShieldIcon";
import LocationIcon from "../svg/LocationIcon";
import LocationIcon2 from "../svg/LocationIcon2";
import DownArrowIcon from "../svg/DownArrowIcon";
// IMG
import HeroImg from "../svg/assets/hero.jpg";
import badgesImg from "../svg/assets/badges.jpg";
import checkatradeImg from "../svg/assets/checkatrade.jpg";
// Carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
// -- domestic
import d1 from "../svg/assets/d/1.jpg";
import d2 from "../svg/assets/d/2.jpg";
import d3 from "../svg/assets/d/3.jpg";
import d4 from "../svg/assets/d/4.jpg";
import d5 from "../svg/assets/d/5.jpg";
import d6 from "../svg/assets/d/6.jpg";
import d7 from "../svg/assets/d/7.jpg";
import d8 from "../svg/assets/d/8.jpg";
import d9 from "../svg/assets/d/9.jpg";
import d10 from "../svg/assets/d/10.jpg";
import d11 from "../svg/assets/d/11.jpg";
import d12 from "../svg/assets/d/12.jpg";
import d13 from "../svg/assets/d/13.jpg";
import d14 from "../svg/assets/d/14.jpg";
import d15 from "../svg/assets/d/15.jpg";
import d16 from "../svg/assets/d/16.jpg";
// -- commercial
import c1 from "../svg/assets/c/1.jpg";
import c2 from "../svg/assets/c/2.jpg";
import c3 from "../svg/assets/c/3.jpg";
import c4 from "../svg/assets/c/4.jpg";
import c5 from "../svg/assets/c/5.jpg";
import c6 from "../svg/assets/c/6.jpg";
import c7 from "../svg/assets/c/7.jpg";
import c8 from "../svg/assets/c/8.jpg";
import c9 from "../svg/assets/c/9.jpg";
import c10 from "../svg/assets/c/10.jpg";

export default () => (
  <Fragment>
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
      <title>Miller &amp; Krish Electrical</title>
      <link rel="canonical" href="https://millerandkrish.com" />
      <meta name="Resource-type" content="Document" />
      <meta
        name="description"
        content="Miller and Krish Electrical, West London and Barkshire"
      />
    </Helmet>
    <Layout>
      <section
        className="pt-20 md:pt-32 pb-16 text-white bg-cover bg-center h-screen flex"
        style={{
          backgroundImage: `url(${HeroImg})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container m-auto px-8 lg:flex">
          <div className="text-center lg:text-left">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl leading-tight">
              We are <i>your</i> <br />
              <strong>local expert Electrical Contractors</strong>
            </h1>
            <p className="text-xl lg:text-2xl mt-6 mb-2 font-light">
              <span>
                <LocationIcon className="inline mx-2" />
              </span>
              West London &amp; East Berkshire
            </p>
            <p className="mt-8 md:mt-12">
              <AnchorLink href="#contact">
                <Button size="lg" className="text-lg">
                  CONTACT US FOR A FREE QUOTE
                </Button>
              </AnchorLink>
            </p>
          </div>
        </div>
        <div className="absolute bottom-0 mb-8 w-full text-center">
          <DownArrowIcon className="inline" />
        </div>
      </section>

      <section id="services" className="py-12 lg:pb-20 lg:pt-20">
        <div className="container mx-auto px-2 sm:px-8 text-left">
          <LabelText className="mb-8 text-xl text-secondary-darker text-center">
            Our Services
          </LabelText>
          <p className="mt-4 text-3xl font-light leading-relaxed text-center text-primary font-semibold">
            Commercial &amp; Domestic
          </p>
          <div className="flex flex-col sm:flex-row sm:-mx-3 mt-8 flex-wrap">
            {servicesData.map((service) => (
              <div
                key={service.title}
                className="flex-50 py-6 pl-3 pr-6 flex flex-row"
              >
                <div>
                  <TickIcon />
                </div>
                <div className="pl-5">
                  <p className="font-semibold text-2xl text-primary">
                    {service.title}
                  </p>
                  <p className="mt-4">{service.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="about" className="py-16 lg:py-20 bg-secondary-lighter">
        <div className="container mx-auto px-5 sm:px-8 text-left">
          <LabelText className="mb-4 text-xl text-secondary-darker text-center">
            Why Us?
          </LabelText>

          <div className="container w-4/5 mx-auto flex flex-col sm:flex-row flex-wrap mb-5">
            <div className="flex-1 sm:flex-25 py-8 px-8 text-center">
              <ShieldIcon className="block mx-auto mb-3" />
              <h3 className="text-xl text-seconary-darker leading-tight font-semibold italic">
                Reliable
              </h3>
              <p>Trust us as experienced and registered experts</p>
            </div>
            <div className="flex-1 sm:flex-25 py-8 px-8 text-center">
              <LocationIcon2 className="block mx-auto mb-3" />
              <h3 className="text-xl text-seconary-darker leading-tight font-semibold italic">
                Local
              </h3>
              <p>We cover a wide regional area and are always local to you</p>
            </div>
          </div>

          <h3 className="text-2xl sm:text-3xl text-primary-darker font-proxima leading-tight">
            We have over <strong>30 years experience</strong> in the
            electrotechnical industry. Our skill set includes domestic
            electrical work, three phase commercial and industrial distribution
            systems, machinery and control systems and AV and data
            infrastructure.
          </h3>

          <p className="mt-8 text-xl font-light leading-relaxed">
            All of our work is designed and installed in accordance with the{" "}
            <strong>BS7671 regulations</strong> (or any other applicable
            standards), when working in residential properties all electrical
            work will be designed and installed for compliance with all parts of
            the <strong>building regulations</strong>. If required it will also
            be notified to your local authority and certified under the
            competent person scheme in accordance with part P. Our friendly
            professional team works closely with all trades to ensure that
            electrical work is completed on time and to budget.
          </p>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Our company takes extreme pride in our due diligence and high
            attention to detail no matter how small or large your project may
            be.
          </p>
          <div className="mt-8 text-xl font-light leading-relaxed text-center">
            <img src={badgesImg} className="inline h-24 md:h-32" alt="NICEIC" />
          </div>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Registered with <strong>NICEIC</strong> and{" "}
            <strong>fully insured</strong> for all work you can rest assured
            that your work is in professional hands.
          </p>
          <div className="mt-8 text-xl font-light leading-relaxed text-center">
            <a
              href="https://www.checkatrade.com/trades/millerandkrishelectricalcontr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={checkatradeImg}
                className="inline h-24 md:h-32"
                alt="Checkatrade"
              />
            </a>
          </div>
        </div>
      </section>

      <section id="projects" className="pt-16 pb-8 bg-white">
        <div className="container mx-auto">
          <LabelText className="text-xl text-secondary-darker text-center">
            PAST PROJECTS
          </LabelText>
        </div>
      </section>
      <SplitSection
        primarySlot={
          <div className="lg:pr-12">
            <h3 className="font-semibold text-2xl text-primary">Domestic</h3>
            <ul className="mt-4 ml-5 text-md font-light leading-relaxed list-disc">
              <li>Consumer unit replacement and new installations</li>
              <li>Kitchen installations</li>
              <li>Circuit additions</li>
              <li>Initial inspection, testing and verification</li>
              <li>New lighting points</li>
              <li>Additional socket outlets</li>
              <li>Garden lighting installations</li>
              <li>Cooker points</li>
              <li>Electrical power showers</li>
              <li>Halogen downlights to LED conversions</li>
              <li>Landlord electrical safety reports</li>
              <li>
                Outdoor power circuits and lighting (including low voltage)
              </li>
              <li>
                Installation of power and consumer units to Log cabins, summer
                houses, garages etc
              </li>
              <li>Remedial work</li>
            </ul>
          </div>
        }
        secondarySlot={
          <Carousel
            dynamicHeight={false}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            stopOnHover={false}
            swipeable={true}
            className="lg:pt-16"
          >
            <div>
              <img src={d1} alt="" />
            </div>
            <div>
              <img src={d2} alt="" />
            </div>
            <div>
              <img src={d3} alt="" />
            </div>
            <div>
              <img src={d4} alt="" />
            </div>
            <div>
              <img src={d5} alt="" />
            </div>
            <div>
              <img src={d6} alt="" />
            </div>
            <div>
              <img src={d7} alt="" />
            </div>
            <div>
              <img src={d8} alt="" />
            </div>
            <div>
              <img src={d9} alt="" />
            </div>
            <div>
              <img src={d10} alt="" />
            </div>
            <div>
              <img src={d11} alt="" />
            </div>
            <div>
              <img src={d12} alt="" />
            </div>
            <div>
              <img src={d13} alt="" />
            </div>
            <div>
              <img src={d14} alt="" />
            </div>
            <div>
              <img src={d15} alt="" />
            </div>
            <div>
              <img src={d16} alt="" />
            </div>
          </Carousel>
        }
      />
      <SplitSection
        primarySlot={
          <div className="lg:pl-12">
            <h3 className="font-semibold text-2xl text-primary">Commercial</h3>
            <ul className="mt-4 ml-5 text-md font-light leading-relaxed list-disc">
              <li>
                Single phase and 3 Phase consumer unit installation, inspection
                testing and verification
              </li>
              <li>Fault finding</li>
              <li>AC/DC drive and motor installation and fault finding</li>
              <li>
                Extensive experience in general fault finding and power quality
                analysis
              </li>
              <li>
                Programmable logic controller fault finding and low level
                programming, mainly Allen Bradley controllers
              </li>
              <li>Electrical installation conditions reports</li>
              <li>UPS and backup power systems</li>
              <li>Remedial work</li>
            </ul>
          </div>
        }
        secondarySlot={
          <Carousel
            dynamicHeight={false}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            stopOnHover={false}
            swipeable={true}
          >
            <div>
              <img src={c1} alt="" />
            </div>
            <div>
              <img src={c2} alt="" />
            </div>
            <div>
              <img src={c3} alt="" />
            </div>
            <div>
              <img src={c4} alt="" />
            </div>
            <div>
              <img src={c5} alt="" />
            </div>
            <div>
              <img src={c6} alt="" />
            </div>
            <div>
              <img src={c7} alt="" />
            </div>
            <div>
              <img src={c8} alt="" />
            </div>
            <div>
              <img src={c9} alt="" />
            </div>
            <div>
              <img src={c10} alt="" />
            </div>
          </Carousel>
        }
        reverseOrder
      />

      <section
        id="testimonials"
        className="py-16 lg:py-20 bg-secondary-lighter"
      >
        <div className="container mx-auto px-2 sm:px-8">
          <LabelText className="mb-8 text-xl text-secondary-darker text-center">
            What our customers say...
          </LabelText>
          <div className="flex flex-col sm:flex-row sm:-mx-3 mt-8 flex-wrap">
            {testimonialsData.map((customer) => (
              <div
                key={customer.customerName}
                className="flex-50 py-6 pl-3 pr-6"
              >
                <div className="flex flex-col items-center mb-4 justify-center">
                  <p className="text-lg text-secondary-darker">
                    {customer.customerName}
                  </p>
                  <p className="text-sm text-primary">
                    {customer.customerLocation}
                  </p>
                </div>
                <p className="text-lg text-center">
                  <i>"{customer.content}"</i>
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <ContactForm />
    </Layout>
  </Fragment>
);
